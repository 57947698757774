import React from "react";
import styled from "@emotion/styled";
import { imprintPageTexts } from "./texts";
import Layout from "./Layout";

const Text = styled.div`
  max-width: 960px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 3%;
  padding-bottom: 3em;

  font-size: 0.8em;

  ul {
    margin: 0;
    list-style-type: none;

    li {
      margin-bottom: 0;
      line-height: 1.4em;
    }
  }

  ul,
  section {
    padding: 0;
    margin: 0;
    margin-top: 24px;
  }
`;

const ImprintPageEN = (props) => {
  const { lang } = props;

  return <Layout lang={lang} hideFooter><Text>{imprintPageTexts[lang]}</Text></Layout>;
};

export default ImprintPageEN;
